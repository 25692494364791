import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "../modalBuilder";
import { toast } from "react-toastify";
import { api } from "@/api";
import { Billing } from "@/api/billing";
import { getIdFromOfficeHandbook, getIdFromRenterHandbook, getIdFromRequisitesHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { useAppSelector } from "@/hooks/useAppSelector";
import { SendBillModal } from "./sendBillPrompt";

interface IBillingEditForm {
  number: string;
  date: string;
  payer: number;
  provider: number;
  clientProvider: number;
  amount: number;
  paid: boolean;
  description: string;
  period: string;
  discount: number;
  office: number;
}

const editBillingFields: InputField[] = [
  { label: "Номер", name: "number", placeholder: "Номер", type: "text", isRequired: true },
  { label: "Дата", name: "date", placeholder: "Дата", type: "datepicker", isRequired: true },
  { label: "Плательщик", name: "payer", placeholder: "Плательщик", type: "renter", isRequired: true },
  { label: "Месяц", name: "period", placeholder: "Выберите месяц", type: "month", isRequired: true },
  { label: "Счёт", name: "provider", placeholder: "Счёт (реквизиты)", type: "requisites", isRequired: true },
  { label: "Счёт", name: "clientProvider", placeholder: "Счёт арендатора", type: "renterRequisites", isRequired: true },
  { label: "Офис", name: "office", placeholder: "Офис (кабинет)", type: "office", isRequired: true },
  { label: "Сумма", name: "amount", placeholder: "Сумма", type: "text", isRequired: true },
  { label: "Скидка (проценты)", name: "discount", placeholder: "Скидка (в процентах)", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: false },
  { label: "Оплачено", name: "paid", placeholder: "Оплачено", type: "boolean", isRequired: true },
];

export function EditBillModal({ disclosure, billing }: { disclosure: ReturnType<typeof useDisclosure>, billing: Billing | null }) {
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  const sendBillDisclosure = useDisclosure();
  const requisites = useAppSelector((state) => state.handbooks.requisites);

  const handleError = useErrorHandling();

  const onSubmit = (data: IBillingEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const payer = await getIdFromRenterHandbook(String(data.payer));
      const provider = await getIdFromRequisitesHandbook(String(data.provider));
      console.log(data);
      const office = await getIdFromOfficeHandbook(String(data.office));
      console.log(office);

      api.billings.update(billing!.id, {
        number: data.number,
        date: data.date,
        payer: Number(payer),
        provider: Number(provider),
        amount: data.amount,
        paid: data.paid,
        description: data.description,
        period: data.period,
        discount: data.discount,
        office: Number(office),
        year: billing!.year,
        clientProvider: data.clientProvider
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);

          const requisite = requisites.find((r) => r.id === Number(provider));
          if (requisite?.billable) {
            sendBillDisclosure.onOpen();
          }
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.billings.delete(billing!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <>
      <SendBillModal disclosure={sendBillDisclosure} paymentId={billing?.id || 0} />
      <ModalBuilder
        title="Редактирование счёта"
        fields={editBillingFields}
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.onOpenChange}
        modalErrors={modalErrors}
        onSubmit={onSubmit}
        onDelete={onDelete}
        isSensitiveData={true}
        submitButtonText="Сохранить"
        defaultValues={{
          number: billing?.number,
          date: billing?.date,
          payer: billing?.payer,
          provider: billing?.provider,
          year: billing?.year,
          amount: billing?.amount,
          paid: billing?.paid,
          description: billing?.description,
          discount: billing?.discount,
          period: billing?.period,
          office: billing?.office,
          clientProvider: billing?.clientProvider
        }} />
    </>
  );
}
