import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Lock } from "@/api/locks";
import { getIdFromLocationHandbook, getIdFromLockSystemHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { loadHandbooks } from "@/utils/loadHandbooks";

interface IEditLockForm {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location: number;
}

const editLockFields: InputField[] = [
  {
    label: "Название замка",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Система",
    name: "lock_system",
    placeholder: "тут будет справочник, сейчас не нужно",
    type: "lock-system",
    isRequired: true
  }, {
    label: "Локация",
    name: "location",
    placeholder: "",
    type: "location",
    isRequired: false
  }, {
    label: "Сервисный ID",
    name: "lock_service_id",
    placeholder: "1",
    type: "text",
    isRequired: true
  }
]

export function EditLockModal({ disclosure, lock }: { disclosure: ReturnType<typeof useDisclosure>, lock: Lock | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IEditLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    const lockSystem = await getIdFromLockSystemHandbook(String(data.lock_system));
    const location = await getIdFromLocationHandbook(String(data.location));

    api.locks.update(lock!.id, {
      display_name: data.display_name,
      lock_system: Number(lockSystem),
      lock_service_id: data.lock_service_id,
      location_id: Number(location)
    })
      .then(_data => {
        loadHandbooks({ handbooks: ["locks"] });
        disclosure.onClose();
        resolve(true);
      })
      .catch(err => {
        const { errorMessage, parsedErrors } = handleError(err);
        setModalErros(parsedErrors || []);
        toast.error(errorMessage);
        resolve(false);
      });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    api.locks.delete(lock!.id)
      .then(_data => {
        loadHandbooks({ handbooks: ["locks"] });
        disclosure.onClose();
        navigate("/dashboard/locks/");
        resolve(true);
      })
      .catch(err => {
        const { errorMessage, parsedErrors } = handleError(err);
        setModalErros(parsedErrors || []);
        toast.error(errorMessage);
        resolve(false);
      });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование замка"
      fields={editLockFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: lock?.display_name,
        lock_system: lock?.lock_system,
        lock_service_id: lock?.lock_service_id,
        location: lock?.location?.id
      }} />
  );
}