import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { getIdFromLocationHandbook, getIdFromLockHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { loadHandbooks } from "@/utils/loadHandbooks";

interface ICreateSharedLockForm {
  lock_id: number;
  location_id: number;
}

const createSharedLockFields: InputField[] = [
  {
    label: "Замок",
    name: "lock_id",
    placeholder: "-",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location_id",
    placeholder: "-",
    type: "location",
    isRequired: true
  }
]

export function CreateSharedLockModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreateSharedLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const lockId = await getIdFromLockHandbook(String(data.lock_id));
      const locationId = await getIdFromLocationHandbook(String(data.location_id));

      api.sharedLocks.create({
        lock_id: Number(lockId),
        location_id: Number(locationId)
      })
        .then(_data => {
          loadHandbooks({ handbooks: ["locks"] });
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание общего замка" modalErrors={modalErrors} fields={createSharedLockFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}