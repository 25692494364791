import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud";

export interface Requisite {
  business_profile: number
  display_name: string
  inn: number
  kpp: number
  ogrn: number
  bank_account: string
  bank_name: string
  bik: string
  correspondent_account: string
  address: string
  phone: string
  email: string
  created_at: string
  currency: number
  billable: boolean
  id: number
}

export interface RequisiteCreate {
  business_profile: number
  display_name: string
  inn: number
  kpp: number
  ogrn: number
  bank_account: string
  bank_name: string
  bik: string
  correspondent_account: string
  address: string
  phone: string
  email: string
  currency: number
  billable: boolean
}

export interface RequisiteUpdate {
  business_profile: number
  display_name: string
  inn: number
  kpp: number
  ogrn: number
  bank_account: string
  bank_name: string
  bik: string
  correspondent_account: string
  address: string
  phone: string
  email: string
  currency: number
  billable: boolean
}

export function RequisitesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/requisites?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Requisite[]>;
    },
    getRenterRequisites: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/requisites/renters?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Requisite[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/requisites/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Requisite>;
    },
    create: async (params: RequisiteCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/requisites?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<Requisite>;
    },
    update: async (id: number, params: RequisiteUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/requisites/${id}?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<Requisite>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/requisites/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
