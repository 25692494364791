import { Button, Checkbox, Input, Link } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { RegisterAdminParams } from "@/api/auth/auth";

export function NewRegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleError = useErrorHandling();

  const onSubmit = useCallback((data: any) => {
    const nData = data as RegisterAdminParams;
    api.auth.register(nData)
      .then(_res => {
        console.log(_res.data.id);
        toast.success("Пользователь успешно зарегистрирован!");
        api.auth.login(data.username, data.password)
          .then((response) => {
            localStorage.clear();
            localStorage.setItem("access_token", response.access_token);
            navigate(searchParams.get("redirect_to") || "/dashboard");
          })
          .catch((err) => {
            const { errorMessage } = handleError(err);
            toast.error(errorMessage);
            console.log(err);
          });
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        console.log(err);
      });
  }, [navigate]);

  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg flex flex-col p-4">
          <div className="mb-4 self-center">
            <img src="/logo.svg" width={"128"} alt="Logo" />
          </div>
          <div className="flex flex-col h-full gap-2 justify-self-end">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full gap-2" autoComplete={"off"}>
              <Input
                label="Имя пользователя"
                placeholder="Введите имя пользователя"
                variant="bordered"
                {...register("username", {
                  required: "Имя пользователя обязательно",
                  pattern: {
                    value: /^[a-zA-Z0-9._]+$/,
                    message: "Имя пользователя может содержать только латинские буквы, цифры, точки и подчеркивания"
                  }
                })}
                isInvalid={!!errors["username"]}
                errorMessage={errors["username"]?.message?.toString()}
              />
              <Input
                label="Пароль"
                placeholder="Введите пароль"
                variant="bordered"
                type="password"
                {...register("password", { required: true })}
                isInvalid={!!errors["password"]}
                errorMessage={errors["password"]?.message?.toString()}
              />
              <Input
                label="Имя"
                placeholder="Введите имя"
                variant="bordered"
                {...register("first_name", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Zа-яА-Я]+$/,
                    message: "Имя может содержать только буквы"
                  }
                })}
                isInvalid={!!errors["first_name"]}
                errorMessage={errors["first_name"]?.message?.toString()}
              />
              <Input
                label="Фамилия"
                placeholder="Введите фамилию"
                variant="bordered"
                {...register("last_name", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Zа-яА-Я]+$/,
                    message: "Фамилия может содержать только буквы"
                  }
                })}
                isInvalid={!!errors["last_name"]}
                errorMessage={errors["last_name"]?.message?.toString()}
              />
              <Input
                label="Отчество"
                placeholder="Введите отчество"
                variant="bordered"
                {...register("middle_name", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Zа-яА-Я]+$/,
                    message: "Отчество может содержать только буквы"
                  }
                })}
                isInvalid={!!errors["middle_name"]}
                errorMessage={errors["middle_name"]?.message?.toString()}
              />
              <Input
                label="Адрес почты"
                placeholder="Введите адрес электронной почты"
                variant="bordered"
                type="email"
                {...register("email", { required: true })}
                className="mb-2"
                isInvalid={!!errors["email"]}
                errorMessage={errors["email"]?.message?.toString()}
              />
              <Input
                label="Номер телефона"
                placeholder="Введите номер телефона"
                variant="bordered"
                {...register("phone_number", { required: false })}
                isInvalid={!!errors["phone_number"]}
                errorMessage={errors["phone_number"]?.message?.toString()}
              />
              <div className="flex flex-row gap-2">
                <Checkbox isSelected={privacyChecked} onValueChange={setPrivacyChecked} />
                <span>Я ознакомился(лась) с <Link href="/auth/privacy" target="_blank">Политикой Приватности</Link> и даю своё согласие на обработку моих персональных данных</span>
              </div>
              <Button color="primary" className="w-full font-semibold" type="submit" isDisabled={!privacyChecked}>
                Зарегистрироваться
              </Button>
              <div className="mt-4 flex w-full flex-col items-center">
                <span>Уже есть аккаунт?</span>
                <Link href="/auth/login" className="font-medium" showAnchorIcon>Войдите в него</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
