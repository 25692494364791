import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { UnlinkedPrinter } from "@/api/printers";

interface ICreatePrinterForm {
  id: string;
  enabled: boolean;
  location: number;
  floor: number;
}

const createPrinterFields: InputField[] = [
  {
    label: "ID Принтера",
    name: "id",
    placeholder: "12345",
    type: "text",
    isRequired: true
  },
  {
    label: "Включен",
    name: "enabled",
    placeholder: "",
    type: "boolean",
    isRequired: true
  },
  {
    label: "Локация",
    name: "location",
    placeholder: "1",
    type: "location",
    isRequired: true
  },
  {
    label: "Этаж",
    name: "floor",
    placeholder: "1",
    type: "text",
    isRequired: true
  }
];

export function CreatePrinterModal({ disclosure, printer }: { disclosure: ReturnType<typeof useDisclosure>, printer: UnlinkedPrinter | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErrors] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreatePrinterForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.printers.create({
        printer_id: data.id,
        enabled: data.enabled,
        location_id: data.location,
        floor: data.floor
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErrors(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Создание принтера"
      modalErrors={modalErrors}
      fields={createPrinterFields}
      isOpen={isOpen}
      isSensitiveData={false}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      defaultValues={{
        id: printer?.id,
        enabled: printer?.enabled
      }}
    />
  );
}
