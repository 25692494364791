import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout";
import { DashboardLoader, IndexLoader, AuthLoader } from "./loaders";
import { LoginPage } from "./pages";
import { SelectProfile } from "./pages/dashboard/selectProfile";
import { DashboardDemo } from "./pages/dashboard/dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
import rootStore from "./store";
import { LocationPage } from "./pages/dashboard/location/[id]";
import { OfficePage } from "./pages/dashboard/location/office/[id]";
import { LocksPage } from "./pages/dashboard/locks";
import { LockPasscodesPage } from "./pages/dashboard/locks/[id]/passcodes";
import { LockSystemsPage } from "./pages/dashboard/lockSystems";
import { UserRolePage } from "./pages/dashboard/userRoles";
import { OfficeTypesPage } from "./pages/dashboard/officeTypes";
import { RentersUsersPage } from "./pages/dashboard/renters/renters";
import { SystemParamsPage } from "./pages/dashboard/system";
import { BillingPage } from "./pages/dashboard/payments";
import { RentedOfficesPage } from "./pages/dashboard/rentedOffices";
import { OfficesPage } from "./pages/dashboard/offices";
import { LocationsPage } from "./pages/dashboard/locations";
import { TicketsPage } from "./pages/dashboard/tickets";
import { TicketPage } from "./pages/dashboard/ticket/[id]";
import { SharedLocksPage } from "./pages/dashboard/sharedLocks";
import { BaseLayout } from "./layout/baseLayout";
import { DocumentTemplatesPage } from "./pages/dashboard/documents";
import { DocumentTemplatePage } from "./pages/dashboard/documents/template";
import { SettingsPage } from "./pages/dashboard/settings";
import { UsersPage } from "./pages/dashboard/users/users";
import { UserRolesEditPage } from "./pages/dashboard/users/[id]/roles";
import { RenterUsersPage } from "./pages/dashboard/renters/[id]/[id]";
import { RenterEmployeesPage } from "./pages/dashboard/renters/[id]/employees";
import { RenterOfficesPage } from "./pages/dashboard/renters/[id]/offices";
import { MyProfilePage } from "./pages/dashboard/me";
import { UserProfilePage } from "./pages/dashboard/users/[id]/[id]";
import { LockLogsPage } from "./pages/dashboard/locks/[id]/[id]";
import { FinancePage } from "./pages/dashboard/finance/finance";
import { RequisitesPage } from "./pages/dashboard/requisites";
import { ErrorBoundaryPage } from "./layout/errorBoundary";
import { NewInviteHashPage } from "./pages/auth/invite/[hash]";
import { NewLoginPage } from "./pages/auth/login";
import { NewAuthHomePage } from "./pages/auth";
import { NewRegisterPage } from "./pages/auth/register";
import { NewResetPage } from "./pages/auth/reset/reset";
import { NewVerifyPage } from "./pages/auth/reset/verify";
import { PrivacyPolicyPage } from "./pages/auth/privacyPolicy";
import { TestPage } from "./pages/dashboard/test";
import { MeetingRoomsPage } from "./pages/dashboard/meetingRooms";
import { MeetingRoomPage } from "./pages/dashboard/meetingRooms/[id]/[id]";
import { PrintersPage } from "./pages/dashboard/printers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        path: "/",
        loader: AuthLoader,
        element: <LoginPage />
      },
      {
        path: "/invite/:hash",
        element: <NewInviteHashPage />
      }
    ]
  },
  {
    path: "/auth",
    element: <BaseLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        path: "/auth",
        loader: IndexLoader,
        element: <NewAuthHomePage />
      },
      {
        path: "/auth/login",
        loader: IndexLoader,
        element: <NewLoginPage />
      },
      {
        path: "/auth/register",
        loader: IndexLoader,
        element: <NewRegisterPage />
      },
      {
        path: "/auth/reset",
        loader: IndexLoader,
        element: <NewResetPage />
      },
      {
        path: "/auth/reset/:username",
        loader: IndexLoader,
        element: <NewVerifyPage />
      },
      {
        path: "/auth/privacy",
        loader: IndexLoader,
        element: <PrivacyPolicyPage />
      }
    ]
  },
  {
    path: "/dashboard",
    element: <Layout />,
    loader: DashboardLoader,
    errorElement: <ErrorBoundaryPage />,
    children: [
      { path: "/dashboard", loader: IndexLoader, element: <DashboardDemo /> },
      { path: "/dashboard/requisites", loader: IndexLoader, element: <RequisitesPage /> },
      { path: "/dashboard/selectProfile", loader: IndexLoader, element: <SelectProfile /> },

      { path: "/dashboard/location/:id", loader: IndexLoader, element: <LocationPage /> },
      { path: "/dashboard/location/:id/office/:officeId", loader: IndexLoader, element: <OfficePage /> },

      { path: "/dashboard/locks", loader: IndexLoader, element: <LocksPage /> },
      { path: "/dashboard/locks/:id", loader: IndexLoader, element: <LockLogsPage /> },
      { path: "/dashboard/locks/:id/passcodes", loader: IndexLoader, element: <LockPasscodesPage /> },

      { path: "/dashboard/sharedLocks", loader: IndexLoader, element: <SharedLocksPage /> },

      { path: "/dashboard/tickets", loader: IndexLoader, element: <TicketsPage /> },
      { path: "/dashboard/tickets/:id", loader: IndexLoader, element: <TicketPage /> },

      { path: "/dashboard/renters", loader: IndexLoader, element: <RentersUsersPage /> },
      { path: "/dashboard/renters/:id", loader: IndexLoader, element: <RenterUsersPage /> },
      { path: "/dashboard/renters/:id/employees", loader: IndexLoader, element: <RenterEmployeesPage /> },
      { path: "/dashboard/renters/:id/offices", loader: IndexLoader, element: <RenterOfficesPage /> },

      { path: "/dashboard/system", loader: IndexLoader, element: <SystemParamsPage /> },

      { path: "/dashboard/officeTypes", loader: IndexLoader, element: <OfficeTypesPage /> },

      { path: "/dashboard/userRoles", loader: IndexLoader, element: <UserRolePage /> },

      { path: "/dashboard/lockSystems", loader: IndexLoader, element: <LockSystemsPage /> },

      { path: "/dashboard/users", loader: IndexLoader, element: <UsersPage /> },
      { path: "/dashboard/users/:id", loader: IndexLoader, element: <UserProfilePage /> },
      { path: "/dashboard/users/:id/roles", loader: IndexLoader, element: <UserRolesEditPage /> },

      { path: "/dashboard/payments", loader: IndexLoader, element: <BillingPage /> },

      { path: "/dashboard/rentedOffices", loader: IndexLoader, element: <RentedOfficesPage /> },

      { path: "/dashboard/offices", loader: IndexLoader, element: <OfficesPage /> },

      { path: "/dashboard/locations", loader: IndexLoader, element: <LocationsPage /> },

      { path: "/dashboard/documents", loader: IndexLoader, element: <DocumentTemplatesPage /> },
      { path: "/dashboard/documents/:id/fill", loader: IndexLoader, element: <DocumentTemplatePage /> },

      { path: "/dashboard/me", loader: IndexLoader, element: <MyProfilePage /> },
      { path: "/dashboard/settings", loader: IndexLoader, element: <SettingsPage /> },

      { path: "/dashboard/finance", loader: IndexLoader, element: <FinancePage /> },

      { path: "/dashboard/meetingRooms", loader: IndexLoader, element: <MeetingRoomsPage /> },
      { path: "/dashboard/meetingRooms/:id", loader: IndexLoader, element: <MeetingRoomPage /> },

      { path: "/dashboard/printers", loader: IndexLoader, element: <PrintersPage /> },

      { path: "/dashboard/test", loader: IndexLoader, element: <TestPage /> },
    ]
  }
]);

export function App() {
  return (
    <Provider store={rootStore}>
      <RouterProvider router={router} />
    </Provider>
  )
}