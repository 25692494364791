import { Scheduler } from "@aldabil/react-scheduler";
import { ru } from 'date-fns/locale';

const EVENTS = [
  {
    event_id: 1,
    title: "Event 1",
    start: new Date("2021/5/2 09:30"),
    end: new Date("2021/5/2 10:30"),
  },
  {
    event_id: 2,
    title: "Event 2",
    start: new Date("2021/5/4 10:00"),
    end: new Date("2021/5/4 11:00"),
  },
];

export function TestPage() {
  return (
    <> 
      <Scheduler
        draggable={false}
        locale={ru}
        view="day"
        day={{
          startHour: 9,
          endHour: 21,
          step: 60
        }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: 9,
          endHour: 21,
          step: 60
        }}
        events={EVENTS}
        hourFormat="24"
        agenda={false}
      />
    </>
  )
}