import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { loadHandbooks } from "@/utils/loadHandbooks";

const createLocationFields: InputField[] = [
  {
    label: "Название локации",
    name: "display_name",
    placeholder: "Коворкинг на Мясницкой",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "ул Краснобогатырская...",
    type: "text",
    isRequired: true
  }, {
    label: "Город",
    name: "city",
    placeholder: "Москва",
    type: "text",
    isRequired: true
  }, {
    label: "Станция метро",
    name: "metro_station",
    placeholder: "Черкизовская",
    type: "text",
    isRequired: true
  }, {
    label: "Район",
    name: "district",
    placeholder: "Преображенский",
    type: "text",
    isRequired: true
  }
]

export function CreateLocationModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: { display_name: string, address: string, city: string, metro_station: string, district: string }) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locations.create({
        display_name: data.display_name,
        address: data.address,
        city: data.city,
        metro_station: data.metro_station,
        district: data.district,
        business_profile: businessProfile.id
      })
        .then(_data => {
          loadHandbooks({ handbooks: ["locations"] });
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);

          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание локации" modalErrors={modalErrors} fields={createLocationFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}