import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getIdFromLocationHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { MeetingRoom } from "@/api/meetingRooms";

interface EditMeetingRoomForm {
  office: number;
  display_name: string;
  hdmi: boolean;
}

const editMeetingRoomFields: InputField[] = [
  {
    label: "Название переговорки",
    name: "display_name",
    placeholder: "Переговорка 1",
    type: "text",
    isRequired: true
  }, {
    label: "Кабинет",
    name: "office",
    placeholder: "",
    type: "office",
    isRequired: true
  }, {
    label: "HDMI",
    name: "hdmi",
    type: "boolean",
    placeholder: "",
    isRequired: false
  }
]

export function EditMeetingRoomModal({ disclosure, meetingRoom }: { disclosure: ReturnType<typeof useDisclosure>, meetingRoom: MeetingRoom | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: EditMeetingRoomForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const office = await getIdFromLocationHandbook(String(data.office));
      const features: string[] = [];

      if(data.hdmi) features.push("HDMI");

      api.meetingRooms.update(meetingRoom?.id || 0, {
        display_name: data.display_name,
        office_id: Number(office),
        picture_url: "",
        features
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.meetingRooms.delete(meetingRoom?.id || 0)
        .then(_data => {
          disclosure.onClose();
          navigate("/dashboard/meetingRooms/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование переговорки"
      fields={editMeetingRoomFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: meetingRoom?.display_name,
        office: meetingRoom?.office_id,
        places: meetingRoom?.places,
        floor: meetingRoom?.places,
        hdmi: (meetingRoom?.features.includes("HDMI"))
      }} />
  );
}