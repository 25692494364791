import { api } from "@/api";

import {
  setTicketCategories,
  setLocations,
  setLocks,
  setLockSystems,
  setOffices,
  setOfficeTypes,
  setRenters,
  setUsers,
  setUserRoles,
  setRequisites,
  setRentedOffices,
  setPayments,
  setRenterRequisites,
} from "@/app/store/handbooks/reducer";

type HandbookMapEntry = {
  apiCall: (args?: any) => Promise<any>;
  action: (data: any) => any;
};

export type Handbook =
  | "ticketCategories"
  | "locations"
  | "locks"
  | "lockSystems"
  | "offices"
  | "officeTypes"
  | "renters"
  | "users"
  | "userRoles"
  | "requisites"
  | "rentedOffices"
  | "billings"
  | "renterRequisites";

  export const handbookMap: Record<Handbook, HandbookMapEntry> = {
    ticketCategories: {
      apiCall: () => api.tickets.categories.list(),
      action: setTicketCategories,
    },
    locations: {
      apiCall: () => api.locations.list(),
      action: setLocations,
    },
    locks: {
      apiCall: () => api.locks.list(),
      action: setLocks,
    },
    lockSystems: {
      apiCall: () => api.lockSystems.list(),
      action: setLockSystems,
    },
    offices: {
      apiCall: () => api.offices.list(),
      action: setOffices,
    },
    officeTypes: {
      apiCall: () => api.officeTypes.list(),
      action: setOfficeTypes,
    },
    renters: {
      apiCall: () => api.renters.list(),
      action: setRenters,
    },
    users: {
      apiCall: () => api.users.list(),
      action: setUsers,
    },
    userRoles: {
      apiCall: () => api.userRoles.list(),
      action: setUserRoles,
    },
    requisites: {
      apiCall: () => api.requisites.list(),
      action: setRequisites,
    },
    renterRequisites: {
      apiCall: () => api.requisites.getRenterRequisites(),
      action: setRenterRequisites,
    },
    rentedOffices: {
      apiCall: () => api.rentedOffices.list(),
      action: setRentedOffices,
    },
    billings: {
      apiCall: (args?: { period: string; year: number; location: number }) => {
        console.log(args);
        return api.billings.list(args?.period!, args?.year!, args?.location!);
      },
      action: setPayments,
    },
  };