import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface Billing {
  id: number;
  number: string;
  date: string;
  payer: number;
  provider: number;
  clientProvider: number;
  amount: number;
  discount: number;
  period: string;
  paid: boolean;
  description: string;
  office: number;
  year: string;
}

export interface BillingCreate {
  number: string;
  date: string;
  payer: number;
  provider: number;
  clientProvider: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
  office: number;
  year: string;
}

export interface BillingUpdate {
  number: string;
  date: string;
  payer: number;
  provider: number;
  clientProvider: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
  office: number;
  year: string;
}

export interface SubmitBillingProps {
  email: boolean;
  whatsapp: boolean;
}

export function BillingsAPI(baseUrl: string) {
  return {
    list: async (period: string, year: number, location: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/billings/?business_profile_id=${businessProfileId}&period=${period}&year=${year}&location=${location}`, undefined, true) as ApiResponse<Billing[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Billing>;
    },
    create: async (billingData: BillingCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/billings/?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<Billing>;
    },
    update: async (id: number, billingData: BillingUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    submit: async (id: number, props: SubmitBillingProps) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/billings/submit/${id}?business_profile_id=${businessProfileId}`, props, true) as ApiResponse<{}>;
    }
  }
}
