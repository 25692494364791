import {
  Button, Checkbox, Link, Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure
} from "@nextui-org/react";
import { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Controller, useForm } from "react-hook-form";
import { getBaseUrl } from "@/utils";
import { useAppSelector } from "@/hooks/useAppSelector";
import useErrorHandling from "@/hooks/useErrorHandling";
import { toast } from "react-toastify";
import { api } from "@/api";

interface SendBillForm {
  whatsapp: boolean;
  email: boolean;
}

export function SendBillModal({
  disclosure,
  paymentId
}: {
  disclosure: ReturnType<typeof useDisclosure>;
  paymentId: number;
}) {
  const {
    handleSubmit,
    control,
  } = useForm<SendBillForm>({
    defaultValues: {
      whatsapp: false,
      email: false,
    }
  });

  const handleError = useErrorHandling();

  const [loading, setLoading] = useState(false);
  const businessProfile = useAppSelector((state) => state.businessProfile.businessProfile);

  const previewBill = useCallback(() => {
    setLoading(true);

    fetch(getBaseUrl() + `/admin/billings/preview/${paymentId}?`
      + new URLSearchParams({
        business_profile_id: businessProfile.id.toString(),
      }), {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("access_token")
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `bills-report-${Math.round(Date.now() / 1000)}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [paymentId]);

  const onHandle = useCallback((data: SendBillForm) => {
    setLoading(true);

    api.billings.submit(paymentId, data)
      .then(res => {
        console.log(res);
        toast.success("Счёт успешно отправлен!");
        setLoading(false);
        disclosure.onClose();
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [previewBill]);

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.onOpenChange}
        portalContainer={document.getElementById("main")!}
        backdrop="blur"
        scrollBehavior="outside"
        isDismissable={false}
        classNames={{
          backdrop: "z-[500]",
          wrapper: "z-[500]"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Отправка счёта</ModalHeader>
              <form onSubmit={handleSubmit(onHandle)}>
                <ModalBody className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <span>Вы создали счёт с указанием счёта, для которого включено автоматическое создание счёта. Вы хотите отправить счёт на указанные мессенджеры?</span>
                    <Link className="cursor-pointer" onClick={previewBill} showAnchorIcon>Как будет выглядеть отправленный счёт?</Link>

                    <div className="flex flex-row gap-2 mt-2">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            isSelected={field.value}
                            onChange={field.onChange}
                          >
                            E-mail
                          </Checkbox>
                        )}
                      />
                      <Controller
                        name="whatsapp"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            isSelected={field.value}
                            onChange={field.onChange}
                          >
                            WhatsApp
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">
                      Нет
                    </Button>
                  </div>
                  <Button
                    color="success"
                    type="submit"
                    variant="flat"
                    startContent={<FaPlus />}
                    isLoading={loading}
                  >
                    Отправить
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
