import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface RentedOffice {
  id: number;
  number: string;
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
  deposit: null | number;
  is_active: boolean;
  office_display_name: string;
  requisite: number;
  renterRequisite: number;
}

export interface RentedOfficeCreate {
  number: string;
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
  deposit: number;
  requisite: number;
  renterRequisite: number;
}

export interface RentedOfficeUpdate {
  number: string;
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
  deposit: number;
  requisite: number;
  renterRequisite: number;
}

export function RentedOfficesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/rented-offices/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<RentedOffice[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/rented-offices/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<RentedOffice>;
    },
    create: async (rentedOfficeData: RentedOfficeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/rented-offices/?business_profile_id=${businessProfileId}`, rentedOfficeData, true) as ApiResponse<RentedOffice>;
    },
    update: async (id: number, rentedOfficeData: RentedOfficeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/rented-offices/${id}?business_profile_id=${businessProfileId}`, rentedOfficeData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/rented-offices/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
