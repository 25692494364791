import { ReactNode } from "react";
import { AdminPermissions } from "@/api/auth/auth.ts";
import { UseDisclosureProps } from "@nextui-org/react";

export enum ColumnType {
  Date = "date",
  DateTime = "datetime",
  String = "string",
  Number = "number",
  Boolean = "boolean",
  User = "user",
  Renter = "renter",
  LockSystem = "lock_system",
  Office = "office",
  UserRole = "user_role",
  OfficeType = "office_type",
  Location = "location",
  Category = "category",
  Actions = "actions",
  Lock = "lock",
  Requisite = "requisite",
  Custom = "custom",
}

export interface TableAction {
  icon: ReactNode;
  onClick: (value: any, row: any) => void;
}

export interface ActionsColumn extends BaseColumn {
  type: ColumnType.Actions;
  actions: TableAction[];
}

export type Column = BaseColumn | ActionsColumn;

export interface BaseColumn {
  key: string;
  label: string;
  sortable?: boolean;
  render?: (value: any, row: any) => any;
  type?: ColumnType;
  permissions?: AdminPermissions[];
  sortKey?: string;
  searchKey?: string;
}

export interface TableBuilderProps {
  rowsPerPage?: number;
  columns: Column[];
  data: any[];
  isFullScreen?: boolean;
  displayOptions?: boolean;
  displaySearch?: boolean;
  removeWrapper?: boolean;
  fullscreenDisclosure?: UseDisclosureProps;
  defaultSortDescriptor?: SortDescriptor;
}

export interface SortDescriptor {
  column: string;
  direction: 'ascending' | 'descending';
}