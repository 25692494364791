import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { FaPencil, FaPlus } from "react-icons/fa6";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { useCallback, useEffect, useState } from "react";
import { LinkedPrinter, UnlinkedPrinter } from "@/api/printers";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useDisclosure } from "@nextui-org/react";
import { CreatePrinterModal } from "@/app/components/modals/printer/createPrinterModal";
import { EditPrinterModal } from "@/app/components/modals/printer/editPrinterModal";

export function PrintersPage() {
  const [printers, setPrinters] = useState<LinkedPrinter[] | null>(null);
  const [unlinkedPrinters, setUnlinkedPrinters] = useState<UnlinkedPrinter[] | null>(null);

  const [linkedPrinter, setLinkedPrinter] = useState<LinkedPrinter | null>(null);
  const [unlinkedPrinter, setUnlinkedPrinter] = useState<UnlinkedPrinter | null>(null);

  const editPrinterDisclosure = useDisclosure();
  const createPrinterDisclosure = useDisclosure();

  const handleError = useErrorHandling();

  useEffect(() => {
    api.printers.list()
      .then(response => {
        setPrinters(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.printers.getUnlinked()
      .then(response => {
        setUnlinkedPrinters(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [editPrinterDisclosure.isOpen, createPrinterDisclosure.isOpen]);

  const createPrinter = useCallback((printer: UnlinkedPrinter) => {
    setUnlinkedPrinter(printer);
    createPrinterDisclosure.onOpenChange();
  }, []);

  const editPrinter = useCallback((printer: LinkedPrinter) => {
    setLinkedPrinter(printer);
    editPrinterDisclosure.onOpenChange();
  }, []);

  return (
    <>
      <CreatePrinterModal disclosure={createPrinterDisclosure} printer={unlinkedPrinter} />
      <EditPrinterModal disclosure={editPrinterDisclosure} printer={linkedPrinter} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Принтеры</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              columns={[
                {
                  label: "ID принтера",
                  key: "printer_id",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Локация",
                  key: "location_id",
                  type: ColumnType.Location,
                  sortable: true
                },
                {
                  label: "Этаж",
                  key: "floor",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  label: "Статус",
                  key: "enabled",
                  type: ColumnType.Boolean,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editPrinter(row); }
                    }
                  ]
                }
              ]}
              data={printers || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "number",
                direction: "ascending"
              }}
            />
          </div>
          {(unlinkedPrinters || []).length > 0 && (
            <><span>Обнаружены непривязанные принтеры:</span>
              <div className="flex flex-col gap-2 mb-2">
                <TableBuilder
                  displayOptions={false}
                  removeWrapper={true}
                  displaySearch={false}
                  columns={[
                    {
                      label: "ID принтера",
                      key: "id",
                      type: ColumnType.String,
                      sortable: true
                    },
                    {
                      label: "URL",
                      key: "url",
                      type: ColumnType.String,
                      sortable: true
                    },
                    {
                      label: "Статус",
                      key: "enabled",
                      type: ColumnType.Boolean,
                      sortable: true
                    },
                    {
                      key: "actions",
                      label: "Действия",
                      type: ColumnType.Actions,
                      actions: [
                        {
                          icon: <FaPlus />,
                          onClick: (_value, row) => { createPrinter(row); }
                        }
                      ]
                    }
                  ]}
                  data={unlinkedPrinters || []}
                  rowsPerPage={10}
                  defaultSortDescriptor={{
                    column: "number",
                    direction: "ascending"
                  }}
                />
              </div></>
          )}
        </div>
      </div>
    </>
  );
}