// FinancePage.tsx

import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  useDisclosure,
} from "@nextui-org/react";
import { toast } from "react-toastify";
import { FaBars, FaDownload } from "react-icons/fa6";

import { RenterChip } from "@/app/components/chips/renterChip";
import { OfficeChip } from "@/app/components/chips/officeChip";
import { RentedOfficeChip } from "@/app/components/chips/rentedOfficeChip";
import { PaymentChip } from "@/app/components/chips/paymentChip";
import { CreateRentedOfficeByOgrnModal } from "@/app/components/modals/office/createRentedOfficeByOgrnModal";
import { CreateBillFromFinanceModal } from "@/app/components/modals/bill/createBillFromFinanceModal";

import {
  formatNumber,
  getBaseUrl,
  getCurrentMonth,
  getMonthInEnglish,
  getMonthNumber,
  getRussianMonthFromEnglish,
  summaryValue,
} from "@/utils";
import { RentPlan, SummaryReport } from "@/api/analytics";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useAppSelector } from "@/hooks/useAppSelector";
import { NewLocationHandbook } from "@/app/components/handbooks/locationHandbook";
import { NewYearHandbook } from "@/app/components/handbooks/service/yearHandbook";
import { loadHandbooks } from "@/utils/loadHandbooks";
import { RentedOffice } from "@/api/rentedOffices";
import { RequisiteChip } from "@/app/components/chips/requisiteChip";

interface SummaryRow {
  payment_plan: number;
  payment_actual: number;
  deposit: number;
  sale: number;
  potential_earnings: number;
  workplaces: number;
  price_per_workplace: number;
  potential_price_per_workplace: number;
  price_per_sqm: number;
  potential_price_per_sqm: number;
  total_square_meters: number;
}

type NumericKeys<T> = {
  [K in keyof T]: T[K] extends number | null | undefined ? K : never;
}[keyof T];

export function FinancePage() {
  const handleError = useErrorHandling();
  const firstLocation = useAppSelector(
    (state) => state.handbooks.location[0]?.id || 0
  );
  const offices = useAppSelector((state) => state.handbooks.office);
  const businessProfile = useAppSelector((state) => state.businessProfile.businessProfile);
  const rentedOffices = useAppSelector((state) => state.handbooks.rentedOffices);
  const payments = useAppSelector((state) => state.handbooks.payments);

  const [activeTab, setActiveTab] = useState<string>(
    getMonthInEnglish(getCurrentMonth())
  );
  const [rawData, setRawData] = useState<SummaryReport | null>(null);
  const [activeLocation, setActiveLocation] = useState<string>(
    firstLocation.toString()
  );
  const [activeYear, setActiveYear] = useState<string>(
    String(new Date().getFullYear())
  );
  const [createPaymentProps, setCreatePaymentProps] = useState<{
    rentedOffice: RentedOffice | null;
    month: number;
    year: string;
    customPrice?: number;
  }>({ rentedOffice: null, month: 1, year: activeYear });
  const [activeOffice, setActiveOffice] = useState<number>(0);

  const createRentedOfficeDisclosure = useDisclosure();
  const createPaymentDisclosure = useDisclosure();

  const downloadReport = useCallback(() => {
    fetch(getBaseUrl() + "/admin/analytics/summary/export?"
      + new URLSearchParams({
        business_profile_id: businessProfile.id.toString(),
        period: getRussianMonthFromEnglish(activeTab),
        year: activeYear,
        location_id: activeLocation
      }), {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("access_token")
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${activeYear}-${activeTab}-${activeLocation}-report-${Math.round(Date.now() / 1000)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        toast.success("Отчёт успешно экспортирован!");
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [activeTab, activeYear, activeLocation, businessProfile]);

  const downloadBillsReport = useCallback(() => {
    fetch(getBaseUrl() + "/admin/billings/report?"
      + new URLSearchParams({
        business_profile_id: businessProfile.id.toString()
      }), {
      method: "POST",
      body: JSON.stringify({
        period: getRussianMonthFromEnglish(activeTab),
        year: Number(activeYear),
        location: Number(activeLocation)
      }),
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `bills-${activeYear}-${activeTab}-${activeLocation}-report-${Math.round(Date.now() / 1000)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        toast.success("Отчёт успешно экспортирован!");
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [activeTab, activeYear, activeLocation, businessProfile]);

  useEffect(() => {
    setActiveLocation(firstLocation.toString());
    setActiveYear(String(new Date().getFullYear()));
    setActiveTab(getMonthInEnglish(getCurrentMonth()));
  }, [firstLocation]);

  const fetchData = useCallback(async () => {
    if (!activeTab || Number(activeLocation) === 0) return;

    try {
      const res = await api.analytics.summary(
        getRussianMonthFromEnglish(activeTab),
        activeYear,
        Number(activeLocation)
      );
      setRawData(res.data);

      loadHandbooks({ 
        handbooks: ["rentedOffices", "billings"],
        arguments: [{ location: activeLocation, year: activeYear, period: getRussianMonthFromEnglish(activeTab) }]
      });
    } catch (err: any) {
      const { errorMessage } = handleError(err);
      toast.error(errorMessage);
    }
  }, [activeTab, activeLocation, activeYear, handleError, api, setRawData]);

  useEffect(() => {
    fetchData();
  }, [fetchData, createRentedOfficeDisclosure.isOpen, createPaymentDisclosure.isOpen, activeYear, activeLocation, activeTab]);

  const data = useMemo(() => {
    if (!rawData) return null;

    const sortedRentPlan = [...rawData.rent_plan].sort((a, b) =>
      a.office_name.localeCompare(b.office_name)
    );

    return {
      ...rawData,
      rent_plan: sortedRentPlan,
    };
  }, [rawData]);

  const summaryRow = useMemo<SummaryRow | null>(() => {
    if (!data) return null;

    const deposit = data.rent_plan.reduce(
      (total, item) => total + (item.deposit ?? 0),
      0
    );
    const payment_plan = data.rent_plan.reduce(
      (total, item) => total + (item.price ?? 0),
      0
    );
    const payment_actual = data.actual_payments.reduce(
      (total, item) => total + (item.total_paid ?? 0),
      0
    );
    const workplaces = data.rent_plan.reduce(
      (total, item) => total + (item.workplace_count ?? 0),
      0
    );
    const potential_earnings = data.rent_plan.reduce(
      (total, item) => total + (item.potential_earning_price ?? 0),
      0
    );

    const calculateAverage = (
      items: RentPlan[],
      key: NumericKeys<RentPlan>
    ) => {
      const validItems = items.filter(
        (item) => typeof item[key] === "number" && (item[key] as number) > 0
      );
      const total = validItems.reduce(
        (sum, item) => sum + (item[key] as number),
        0
      );
      return validItems.length > 0 ? total / validItems.length : 0;
    };

    const potential_price_per_sqm = calculateAverage(
      data.rent_plan,
      "potential_price_per_sqm"
    );
    const potential_price_per_workplace = calculateAverage(
      data.rent_plan,
      "potential_workspace_earning_price"
    );
    const price_per_sqm = calculateAverage(data.rent_plan, "price_per_sqm");
    const price_per_workplace = calculateAverage(
      data.rent_plan,
      "price_per_workplace"
    );

    const total_square_meters = offices.filter(o => o.location == Number(activeLocation)).reduce(
      (total, item) => total + (item.area ?? 0),
      0
    );

    return {
      deposit,
      payment_actual,
      payment_plan,
      sale: 0,
      workplaces,
      potential_earnings,
      potential_price_per_sqm,
      potential_price_per_workplace,
      price_per_sqm,
      price_per_workplace,
      total_square_meters,
    };
  }, [data, offices]);

  const columns = useMemo(() => {
    return [
      {
        key: "office",
        label: (
          <div className="flex flex-col">
            <span>Офис</span>
          </div>
        ),
      },
      {
        key: "renter",
        label: (
          <div className="flex flex-col">
            <span>Арендатор</span>
          </div>
        ),
      },
      {
        key: "potential_income",
        label: (
          <div className="flex flex-col">
            <span>Потенциальная выручка</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.potential_earnings)}
            </span>
          </div>
        ),
      },
      {
        key: "payment_plan",
        label: (
          <div className="flex flex-col">
            <span>План оплаты</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.payment_plan)}
            </span>
          </div>
        ),
      },
      {
        key: "requisite",
        label: (
          <div className="flex flex-col">
            <span>Реквизиты</span>
          </div>
        ),
      },
      {
        key: "actual_payment",
        label: (
          <div className="flex flex-col">
            <span>Фактическая оплата</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.payment_actual)}
            </span>
          </div>
        ),
      },
      {
        key: "deposit",
        label: (
          <div className="flex flex-col">
            <span>Депозит</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.deposit)}
            </span>
          </div>
        ),
      },
      {
        key: "work_places",
        label: (
          <div className="flex flex-col">
            <span>Рабочие места</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.workplaces)}
            </span>
          </div>
        ),
      },
      {
        key: "price_per_place",
        label: (
          <div className="flex flex-col">
            <span>Цена за рабочее место</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.price_per_workplace)}
            </span>
          </div>
        ),
      },
      {
        key: "potential_price_per_place",
        label: (
          <div className="flex flex-col">
            <span>Пот. цена за раб. место</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.potential_price_per_workplace)}
            </span>
          </div>
        ),
      },
      {
        key: "square_meters",
        label: (
          <div className="flex flex-col">
            <span>Площадь (м2)</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.total_square_meters)}
            </span>
          </div>
        ),
      },
      {
        key: "price_per_square",
        label: (
          <div className="flex flex-col">
            <span>Цена за м2</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.price_per_sqm)}
            </span>
          </div>
        ),
      },
      {
        key: "potential_price_per_square",
        label: (
          <div className="flex flex-col">
            <span>Пот. цена за м2</span>
            <span className="text-foreground-900">
              {summaryValue(summaryRow?.potential_price_per_sqm)}
            </span>
          </div>
        ),
      },
    ];
  }, [summaryRow]);

  const handlePaymentFallback = useCallback(
    (rentedOfficeId: number, customPrice?: number) => {
      const rentedOffice = rentedOffices.find(el => el.id == rentedOfficeId)!;
      
      setCreatePaymentProps({
        rentedOffice,
        month: getMonthNumber(activeTab),
        customPrice,
        year: activeYear
      });

      createPaymentDisclosure.onOpen();
    },
    [setCreatePaymentProps, createPaymentDisclosure, activeYear]
  );

  const handleRentedOfficeFallback = useCallback(() => {
    createRentedOfficeDisclosure.onOpen();
  }, [createRentedOfficeDisclosure]);

  const getKeyValue = useCallback(
    (item: RentPlan, key: any) => {
      const numberFields: { [key: string]: (item: RentPlan) => string } = {
        deposit: (item) => formatNumber(item.deposit),
        work_places: (item) => formatNumber(item.workplace_count),
        price_per_place: (item) => formatNumber(item.price_per_workplace),
        potential_price_per_place: (item) =>
          formatNumber(item.potential_workspace_earning_price),
        price_per_square: (item) => formatNumber(item.price_per_sqm),
        potential_price_per_square: (item) =>
          formatNumber(item.potential_price_per_sqm),
      };

      if (numberFields[key]) {
        return numberFields[key](item);
      }

      switch (key) {
        case "potential_income":
          return <OfficeChip
            officeId={item.office_id ?? 0}
            openCallback={fetchData}
            displayName={formatNumber(item.potential_earning_price)}
          />;
        case "requisite":
          return <RequisiteChip
            requisiteId={
              payments.find(payment => 
                payment.id == data?.actual_payments.find(actualPayment => actualPayment.office_id == item.office_id)?.billing_id
              )?.provider ||
              rentedOffices.find(rentedOffice => rentedOffice.id == item.rent_id)?.requisite || 0
            }
          />
        case "paid":
          return <Checkbox isDisabled defaultSelected />
        case "office":
          return <OfficeChip
            officeId={item.office_id ?? 0}
            openCallback={fetchData}
          />;
        case "renter":
          return <RenterChip renterId={item.renter_id ?? 0} />;
        case "payment_plan":
          return (
            <RentedOfficeChip
              displayName={formatNumber(item.price)}
              rentedOfficeId={item.rent_id ?? 0}
              fallbackCallback={() => {
                setActiveOffice(item.office_id);
                handleRentedOfficeFallback();
              }}
              openCallback={fetchData}
            />
          );
        case "actual_payment":
          const actualPayment =
            data?.actual_payments.find(
              (el) => el.office_id === item.office_id
            )?.total_paid ?? 0;
          return (
            <PaymentChip
              displayAmount={formatNumber(actualPayment)}
              plannedAmount={item.price}
              paymentId={
                data?.actual_payments.find(
                  (el) => el.office_id === item.office_id
                )?.billing_id ?? 0
              }
              fallbackCallback={() =>
                handlePaymentFallback(item.rent_id ?? 0, item.price ?? 0)
              }
              openCallback={fetchData}
            />
          );
        case "square_meters":
          const area =
            offices.find((el) => el.id === item.office_id)?.area ?? 0;
          return formatNumber(area);
        default:
          return "н/д";
      }
    },
    [offices, data, handlePaymentFallback, handleRentedOfficeFallback]
  );

  return (
    <>
      <CreateBillFromFinanceModal
        disclosure={createPaymentDisclosure}
        paymentProps={createPaymentProps}
      />
      <CreateRentedOfficeByOgrnModal
        disclosure={createRentedOfficeDisclosure}
        officeId={activeOffice}
      />
      <div className="flex flex-col gap-4 mt-4">
        {/* <div className="flex flex-col-reverse md:flex-row gap-2">
          <div className="flex flex-col gap-2 md:flex-grow">
            <TopLocationsTable data={rawData} />
            <RentTrendChart data={rawData} />
          </div>

          <div className="flex flex-col gap-2 md:min-w-96">
            <SummaryPieChart data={rawData} />
            <PotentialEarningsTable data={rawData} />
          </div>
        </div> */}

        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2">
            {/* @ts-ignore */}
            <NewLocationHandbook
              errorMessage=""
              isInvalid={false}
              onSelectionChange={(key: any) => setActiveLocation(key)}
              selectedKey={activeLocation}
            />
            {/* @ts-ignore */}
            <NewYearHandbook
              errorMessage=""
              isInvalid={false}
              onSelectionChange={(key: any) => setActiveYear(key)}
              selectedKey={activeYear}
            />
          </div>
          <div className="flex flex-row gap-2">
            <Button
              color="primary"
              variant="flat"
              startContent={<FaDownload />}
              onClick={downloadReport}
            >
              Экспорт в Excel
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  color="primary"
                  variant="flat"
                  startContent={<FaBars />}
                />
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={() => {}}>Отправить счета всем</DropdownItem>
                <DropdownItem onClick={() => { downloadBillsReport(); }}>Отчёт по выставленным счетам</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <Tabs
            variant="light"
            selectedKey={activeTab}
            onSelectionChange={(e) => setActiveTab(e.toString())}
          >
            <Tab key="january" title="Январь" />
            <Tab key="february" title="Февраль" />
            <Tab key="march" title="Март" />
            <Tab key="april" title="Апрель" />
            <Tab key="may" title="Май" />
            <Tab key="june" title="Июнь" />
            <Tab key="july" title="Июль" />
            <Tab key="august" title="Август" />
            <Tab key="september" title="Сентябрь" />
            <Tab key="october" title="Октябрь" />
            <Tab key="november" title="Ноябрь" />
            <Tab key="december" title="Декабрь" />
          </Tabs>

          <div className="overflow-y-auto">
            <Table
              isHeaderSticky
              removeWrapper
              isStriped
              aria-label="Dynamic table"
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.key}>{column.label}</TableColumn>
                )}
              </TableHeader>

              <TableBody items={data?.rent_plan || []}>
                {(item) => (
                  <TableRow key={`${item.office_id}_${item.renter_id}`} className="border-b-2 border-gray-300 dark:border-zinc-800">
                    {(columnKey) => (
                      <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
