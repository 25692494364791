import React, { useState, useEffect, useMemo } from "react";
import { Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Accordion, AccordionItem, Checkbox, Button } from "@nextui-org/react";
import { TableBuilderProps, SortDescriptor } from "./tableTypes";
import { renderCell } from "./renderers";
import { FullScreenModal } from "./fullscreenModal";
import { useDisclosure } from "@nextui-org/react";

export function TableBuilder({
  rowsPerPage = 10,
  columns,
  data,
  isFullScreen = false,
  displayOptions = true,
  displaySearch = true,
  removeWrapper = false,
  defaultSortDescriptor = { column: "id", direction: "ascending" }
}: TableBuilderProps) {
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>(defaultSortDescriptor);
  const [pages, setPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(columns.map((column) => column.searchKey || column.key));
  const [exportColumns, setExportColumns] = useState<string[]>(
    columns.map((column) => column.key).filter((key) => key !== "action")
  );
  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    if (data[0] && !data[0].id) {
      data = data.map((item, index) => {
        item.id = index;
        return item;
      });
    }

    const sorted = sort(data, sortDescriptor);
    setTableData(sorted);
    
    setPages(Math.ceil(data.length / rowsPerPage));
  }, [data, rowsPerPage]);

  const onSortChange = (e: SortDescriptor) => {
    setSortDescriptor(e);
    const sorted = sort(tableData, e);
    setTableData(sorted);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleColumnChange = (key: string) => {
    setSelectedColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const handleExportColumnChange = (key: string) => {
    setExportColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const filterData = (data: any[], query: string, columns: string[]) => {
    if (!query) return data;
    return data.filter((row) =>
      columns.some((column) =>
        row[column]?.toString().toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filterData(tableData, searchQuery, selectedColumns).slice(
      start,
      end
    );
  }, [page, rowsPerPage, tableData, searchQuery, selectedColumns]);

  function sort<T extends T[]>(items: T[], sortDescriptor: SortDescriptor): T[] {
    const { column, direction } = sortDescriptor;
    
    const sortKey = columns.find(c => c.key == column)?.sortKey;
    const columnKey = sortKey || column;

    if (items.length === 0 || !(column in items[0])) return items;

    return [...items].sort((a, b) => {
      const aValue = a[columnKey as keyof T];
      const bValue = b[columnKey as keyof T];

      if (aValue === bValue) return 0;

      const order = direction === "ascending" ? 1 : -1;
      return (aValue > bValue ? 1 : -1) * order;
    });
  }

  const exportToCSV = () => {
    const headers = exportColumns.map((colKey) => {
      const column = columns.find((col) => col.key === colKey);
      return column?.label || colKey;
    });
    const csvRows = [
      headers.join(","),
      ...tableData.map((row) =>
        exportColumns.map((colKey) => row[colKey]).join(",")
      ),
    ];
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {!isFullScreen && (
        <FullScreenModal disclosure={fullscreenDisclosure}>
          <TableBuilder
            rowsPerPage={rowsPerPage}
            columns={columns}
            data={data}
            isFullScreen={true}
            displayOptions={displayOptions}
            displaySearch={displaySearch}
          />
        </FullScreenModal>
      )}

      <div className="flex flex-col gap-2 mb-2">
        {displaySearch && (
          <Input
            type="text"
            placeholder="Поиск"
            variant="bordered"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
        {displayOptions && (
          <Accordion>
            <AccordionItem title="Опции">
              <div className="flex flex-col gap-2 mb-8">
                <span>Включать результаты поиска по столбцам:</span>
                <div className="flex flex-row gap-4">
                  {columns.map((column) => (
                    column.key !== "action" && (
                      <label key={column.key}>
                        <Checkbox
                          defaultSelected={selectedColumns.includes(column.key)}
                          onChange={() => handleColumnChange(column.key)}
                          size="sm"
                        />
                        {column.label}
                      </label>
                    )
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <div className="flex flex-col gap-2">
                  <span>Экспортировать данные в CSV:</span>
                  <div className="flex flex-row gap-4">
                    {columns.map((column) => (
                      column.key !== "action" && (
                        <label key={column.key}>
                          <Checkbox
                            defaultSelected={exportColumns.includes(column.key)}
                            onChange={() => handleExportColumnChange(column.key)}
                            size="sm"
                          />
                          {column.label}
                        </label>
                      )
                    ))}
                  </div>
                  <Button onClick={exportToCSV} className="max-w-fit mt-4">
                    Экспорт в CSV
                  </Button>
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        )}
      </div>
      <Table
        isStriped
        isHeaderSticky
        onSortChange={onSortChange as any}
        sortDescriptor={sortDescriptor}
        removeWrapper={removeWrapper}
        bottomContent={
          <>
            {pages > 1 && (
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  color="default"
                  variant="flat"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            )}
          </>
        }
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} allowsSorting={column.sortable || false}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={items} emptyContent={"Нет данных."}>
          {(item) => (
            <TableRow key={(item as any).id}>
              {columns.map((column) => (
                <TableCell key={column.key}>
                  {renderCell(item, column)}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}