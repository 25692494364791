import { api } from "@/api";
import { MeetingRoom, MeetingRoomRent } from "@/api/meetingRooms";
import { EditMeetingRoomModal } from "@/app/components/modals/meetingRoom/editMeetingRoomModal";
import useErrorHandling from "@/hooks/useErrorHandling";
import { Button, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaPen, FaPlus } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Scheduler } from "@aldabil/react-scheduler";
import { ru } from 'date-fns/locale';
import { useAppSelector } from "@/hooks/useAppSelector";
import { CreateMeetingRoomRentModal } from "@/app/components/modals/meetingRoom/createMeetingRoomRentModal";
import { EditMeetingRoomRentModal } from "@/app/components/modals/meetingRoom/editMeetingRoomRentModal";
import ym from "react-yandex-metrika";

export function MeetingRoomPage() {
  const { id } = useParams();

  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom | null>(null);
  const [rents, setRents] = useState<MeetingRoomRent[] | null>(null);
  const [randomNumber, setRandomNumber] = useState<number>(Math.random());
  const [called, setCalled] = useState<boolean>(false);

  const renters = useAppSelector(state => state.handbooks.renter);
  const users = useAppSelector(state => state.handbooks.user);

  useEffect(() => {
    if(called) return;

    ym('reachGoal', 'rent_meeting_room_start');
    setCalled(true);
  }, []);

  const events = useMemo(() => {
    return rents?.map(rent => {
      const user = users.find(user => user.id == rent.user_id);

      return {
        event_id: rent.id,
        title: `${renters.find(renter => rent.renter_id == renter.id)?.display_name}: ${user?.first_name} ${user?.last_name}`,
        start: new Date(rent.rent_from),
        end: new Date(rent.rent_to)
      }
    }) || [];
  }, [rents]);

  const createRentDisclosure = useDisclosure();
  const updateDisclosure = useDisclosure();

  const handleError = useErrorHandling();

  useEffect(() => {
    api.meetingRooms.get(Number(id))
      .then(res => {
        setMeetingRoom(res.data);

        api.meetingRooms.getRents(Number(id))
          .then(res => {
            setRents(res.data);
          })
          .catch(err => {
            const { errorMessage } = handleError(err);
            toast.error(errorMessage);
            console.log(err);
          });
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        console.log(err);
      });
  }, [updateDisclosure.isOpen, randomNumber]);

  const editCallback = useCallback(() => {
    setRandomNumber(Math.random());
  }, [setRandomNumber]);

  return (
    <>
      <CreateMeetingRoomRentModal disclosure={createRentDisclosure} meetingRoom={meetingRoom} />
      <EditMeetingRoomModal disclosure={updateDisclosure} meetingRoom={meetingRoom} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">{meetingRoom?.display_name}</span>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPen />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => updateDisclosure.onOpen()}
              >
                Редактировать
              </Button>
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="bordered"
                className="max-w-fit"
                onClick={() => createRentDisclosure.onOpen()}
              >
                Добавить бронь
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Scheduler
              draggable={false}
              locale={ru}
              view="week"
              day={{
                startHour: 9,
                endHour: 21,
                step: 60
              }}
              deletable={false}
              customEditor={(scheduler) => <EditMeetingRoomRentModal scheduler={scheduler} rents={rents} callback={editCallback} /> }
              week={{
                weekDays: [0, 1, 2, 3, 4, 5, 6],
                weekStartOn: 1,
                startHour: 9,
                endHour: 21,
                step: 60
              }}
              events={events}
              hourFormat="24"
              agenda={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}