import { Button, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { RentedOffice } from "@/api/rentedOffices";
import { toast } from "react-toastify";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { FaMagnifyingGlass, FaPencil } from "react-icons/fa6";
import { api } from "@/api";
import { EditRentedOfficeModal } from "@/app/components/modals/office/editRentedOfficeModal";
import useErrorHandling from "@/hooks/useErrorHandling";
import { CreateRentedOfficeByOgrnModal } from "@/app/components/modals/office/createRentedOfficeByOgrnModal";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

interface TabLocationInterface {
  id: number;
  display_name: string;
}

type TabLocation = TabLocationInterface | null;

export function RentedOfficesPage() {
  const [rawOffices, setRawOffices] = useState<RentedOffice[] | null>(null);
  const [offices, setOffices] = useState<RentedOffice[] | null>(null);
  const [office, setOffice] = useState<RentedOffice | null>(null);
  const [tabs, setTabs] = useState<TabLocation[] | null>(null);
  const [activeTab, setActiveTab] = useState<any>("active");
  const [displayMode, setDisplayMode] = useState<any>("all");

  const cachedLocations = useAppSelector(state => state.handbooks.location);
  const cachedOffices = useAppSelector(state => state.handbooks.office);

  const handleError = useErrorHandling();

  const createRentedOfficeByOgrn = useDisclosure();
  const editRentedOfficeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editRentedOffice = (office: RentedOffice) => {
    setOffice(office);
    editRentedOfficeModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.rentedOffices.list()
      .then(async response => {
        setRawOffices(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    editRentedOfficeModalDisclosure.isOpen,
    createRentedOfficeByOgrn.isOpen
  ]);

  useEffect(() => {
    const locations: TabLocation[] = [];

    rawOffices?.forEach(office => {
      if (office.office) {
        const nOffice = cachedOffices.find(o => o.id == office.office);

        if (nOffice) {
          if (!locations.find(loc => loc?.id == nOffice.location)) locations.push({
            id: nOffice.location,
            display_name: cachedLocations.find(l => l.id == nOffice.location)?.display_name || "Локация " + nOffice.location
          });
        }
      }
    });

    setTabs(locations);
  }, [rawOffices, cachedOffices, cachedLocations]);

  useEffect(() => {
    if (!rawOffices) {
      setOffices(null);
      return;
    }

    let filtered = [...rawOffices];

    if (activeTab !== "all") {
      filtered = filtered.filter(office => {
        const cachedOffice = cachedOffices.find(o => o.id === office.office);
        return cachedOffice?.location == activeTab;
      });
    }

    if (displayMode !== "all") {
      const isActive = displayMode === "active";
      filtered = filtered.filter(office => office.is_active === isActive);
    }

    setOffices(filtered);
  }, [rawOffices, activeTab, displayMode, cachedOffices]);

  return (
    <>
      <CreateRentedOfficeByOgrnModal officeId={0} disclosure={createRentedOfficeByOgrn} />
      <EditRentedOfficeModal disclosure={editRentedOfficeModalDisclosure} office={office} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Договора</span>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaMagnifyingGlass />}
                color="primary"
                variant="bordered"
                className="max-w-fit"
                onClick={() => createRentedOfficeByOgrn.onOpen()}
              >
                Создать по ОГРН/ОГРНИП
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2 mb-4">
            <Tabs color="primary" variant="bordered" selectedKey={activeTab} onSelectionChange={setActiveTab}>
              <Tab key="all" title="Все локации" />
              {tabs?.map(tab => (
                <Tab key={tab?.id} title={tab?.display_name}></Tab>
              ))}
            </Tabs>
            <Tabs
              color="primary"
              variant="bordered"
              selectedKey={displayMode}
              onSelectionChange={setDisplayMode}
              aria-label="Статус активности"
            >
              <Tab key="all" title="Все" />
              <Tab key="active" title="Активные" />
              <Tab key="inactive" title="Неактивные" />
            </Tabs>
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              fullscreenDisclosure={fullscreenDisclosure}
              columns={[
                {
                  label: "Офис",
                  key: "office",
                  type: ColumnType.Office,
                  sortable: true,
                  sortKey: "office_display_name",
                  searchKey: "office_display_name"
                },
                {
                  label: "Арендатор",
                  key: "renter",
                  type: ColumnType.Renter,
                  sortable: true
                },
                {
                  label: "Цена",
                  key: "price",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  label: "Описание",
                  key: "description",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Депозит",
                  key: "deposit",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Статус",
                  key: "is_active",
                  type: ColumnType.Boolean,
                  sortable: true
                },
                {
                  label: "Дата начала",
                  key: "start_date",
                  type: ColumnType.Date,
                  sortable: true
                },
                {
                  label: "Дата окончания",
                  key: "end_date",
                  type: ColumnType.Date,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editRentedOffice(row); }
                    }
                  ]
                }
              ]}
              data={offices || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "office",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
