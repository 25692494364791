import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { OfficeLocation } from "@/api/locations";
import { getIdFromLockHandbook, getIdFromOfficeTypeHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { loadHandbooks } from "@/utils/loadHandbooks";

interface ICreateOfficeForm {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  floor: number;
  office_lock: number;
  commissioning_date: string;
  workplace_count: number;
}

const createOfficeFields: InputField[] = [
  {
    label: "Название офиса",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Тип офиса",
    name: "office_type",
    placeholder: "",
    type: "office-type",
    isRequired: true
  }, {
    label: "Площадь",
    name: "area",
    placeholder: "10м",
    type: "text",
    isRequired: true
  }, {
    label: "Стоимость",
    name: "price",
    placeholder: "90000",
    type: "text",
    isRequired: true
  }, {
    label: "Количество комнат",
    name: "room_count",
    placeholder: "2",
    type: "text",
    isRequired: true
  }, {
    label: "Этаж",
    name: "floor",
    placeholder: "5",
    type: "text",
    isRequired: true
  }, {
    label: "Замок",
    name: "office_lock",
    placeholder: "",
    type: "lock",
    isRequired: true
  }, {
    label: "Дата ввода в эксплуатацию",
    name: "commissioning_date",
    placeholder: "",
    type: "datetime-local",
    isRequired: false
  }, {
    label: "Количество рабочих мест",
    name: "workplace_count",
    placeholder: "Оставьте пустым для автоматического заполнения",
    type: "text",
    isRequired: false
  }
]

export function CreateOfficeWithLocationModal({ disclosure, location }: { disclosure: ReturnType<typeof useDisclosure>, location: OfficeLocation | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreateOfficeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const officeType = await getIdFromOfficeTypeHandbook(String(data.office_type));
      const lock = await getIdFromLockHandbook(String(data.office_lock));
      const workplaceCount = (
        data.workplace_count && data.workplace_count.toString().length > 0
          ? data.workplace_count
          : Math.floor((data.area || 0) / 4.5)
      );

      api.offices.create({
        display_name: data.display_name,
        office_type: Number(officeType),
        area: data.area,
        floor: data.floor,
        office_lock: Number(lock),
        price: data.price,
        room_count: data.room_count,
        location: Number(location?.id),
        commissioning_date: data.commissioning_date,
        workplace_count: workplaceCount
      })
        .then(_data => {
          loadHandbooks({ handbooks: ["offices"] });
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание офиса" modalErrors={modalErrors} fields={createOfficeFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}