import { api } from "@/api";
import { MeetingRoom } from "@/api/meetingRooms";
import { CreateMeetingRoomModal } from "@/app/components/modals/meetingRoom/createMeetingRoomModal";
import { useAppSelector } from "@/hooks/useAppSelector";
import useErrorHandling from "@/hooks/useErrorHandling";
import { Button, Spinner, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { FaPlus, FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MeetingRoomCard({ room }: { room: MeetingRoom }) {
  const navigate = useNavigate();
  const callback = useCallback(() => {
    navigate("/dashboard/meetingRooms/" + room.id)
  }, []);

  return (
    <div className="cursor-pointer flex flex-col rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4" onClick={callback}>
      <span>{room.display_name}</span>
      <span className="flex flex-row items-center gap-2 text-foreground text-opacity-80">
        {room.places}
        <FaUser className="text-xs" />
      </span>
      <span className="flex flex-row gap-2 text-sm uppercase font-semibold text-foreground text-opacity-80">
        {room.features.length >= 1 ? (
          <span>Есть {room.features.join(", ")}</span>
        ) : null}
        <span>{room.floor} этаж</span>
      </span>
    </div>
  );
}

export function MeetingRoomsPage() {
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[] | null>([]);
  const [viewLocations, setViewLocations] = useState<number[]>([]);
  const createDisclosure = useDisclosure();

  const locations = useAppSelector(state => state.handbooks.location);

  const handleError = useErrorHandling();

  useEffect(() => {
    api.meetingRooms.list()
      .then(res => {
        const locations = res.data.map(room => room.location_id);
        setViewLocations([...new Set(locations)]);
        setMeetingRooms(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        console.log(err);
      });
  }, [createDisclosure.isOpen]);

  return (
    <>
      <CreateMeetingRoomModal disclosure={createDisclosure} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Переговорки</span>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createDisclosure.onOpen()}
              >
                Создать переговорку
              </Button>
            </div>
          </div>
          <div className="max-w-fit flex flex-col gap-4">
            {meetingRooms ? (
              <>
                {viewLocations.map(locationId => (
                  <div className="flex flex-col gap-1">
                    <span className="text-sm uppercase font-semibold text-foreground text-opacity-80">
                      {locations.find(loc => loc.id == locationId)?.display_name || "н/а"}
                    </span>
                    <div className="flex flex-row flex-wrap gap-2">
                      {meetingRooms.filter(room => room.location_id == locationId).map(room => (
                        <MeetingRoomCard room={room} />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (<Spinner color="default" size="sm" />)}
          </div>
        </div>
      </div>
    </>
  )
}