import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { getIdFromCategoryHandbook, getIdFromOfficeHandbook } from "@/utils/getIdFromHandbooks.ts";
import { Ticket } from "@/api/tickets/tickets.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { loadHandbooks } from "@/utils/loadHandbooks";

interface UpdateTicketForm {
  title: string;
  description: string;
  status: string;
  category: number;
  office: number;
  user: number;
}

const updateTicketFields: InputField[] = [
  {
    label: "Тема обращения",
    name: "title",
    placeholder: "Введите тему обращения",
    type: "text",
    isRequired: true
  }, {
    label: "Описание",
    name: "description",
    placeholder: "Введите описание обращения",
    type: "text"
  }, {
    label: "Статус",
    name: "status",
    placeholder: "",
    type: "text",
    isRequired: true
  }, {
    label: "Категория",
    name: "category",
    placeholder: "",
    type: "category",
    isRequired: true
  }, {
    label: "Офис",
    name: "office",
    placeholder: "",
    type: "office",
    isRequired: true
  }
]

export function EditTicketModal({ disclosure, ticket }: { disclosure: ReturnType<typeof useDisclosure>, ticket: Ticket | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: UpdateTicketForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const office = await getIdFromOfficeHandbook(String(data.office));
      const category = await getIdFromCategoryHandbook(String(data.category));

      api.tickets.update(ticket!.id, {
        title: data.title,
        category: Number(category),
        description: data.description,
        status: data.status,
        office: Number(office),
      })
        .then(_data => {
          loadHandbooks({ handbooks: ["ticketCategories"] });
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.tickets.delete(ticket!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  return (
    <ModalBuilder
      title="Редактироваие тикета"
      fields={updateTicketFields}
      isOpen={isOpen}
      isSensitiveData={true}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      submitButtonText="Сохранить"
      defaultValues={{
        title: ticket?.title,
        category: ticket?.category,
        description: ticket?.description,
        status: ticket?.status,
        office: ticket?.office
      }}
    />
  );
}