import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud";

export interface MeetingRoom {
  id: number;
  location_id: number;
  office_id: number;
  display_name: string;
  picture_url: string;
  places: number;
  floor: number;
  features: string[]
}

export interface CreateMeetingRoomParams {
  office_id: number;
  display_name: string;
  picture_url: string;
  features: string[];
}

export interface UpdateMeetingRoomParams {
  office_id: number;
  display_name: string;
  picture_url: string;
  features: string[];
}

export interface MeetingRoomRent {
  id: number;
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface CreateMeetingRoomRent {
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
}

interface UpdateMeetingRoomRent {
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
}

export function MeetingRoomsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/meeting-rooms/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoom[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoom>;
    },
    create: async (data: CreateMeetingRoomParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/meeting-rooms/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoom>;
    },
    update: async (id: number, data: CreateMeetingRoomParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoom>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, true) as {};
    },
    getRents: async (roomId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/meeting-rooms/${roomId}/rents/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoomRent[]>;
    },
    createRent: async (data: CreateMeetingRoomRent) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/meeting-rooms/rents/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoomRent>;
    },
    updateRent: async (rentId: number, data: UpdateMeetingRoomRent) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/meeting-rooms/rents/${rentId}/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoomRent>;
    },
    cancelRent: async (rentId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/meeting-rooms/rents/${rentId}/?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
