import { ModalError } from "./../modalBuilder";
import { getIdFromRenterHandbook, getIdFromUserHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useEffect, useMemo, useState } from "react";
import { MeetingRoomRent } from "@/api/meetingRooms";
import { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { Controller, useForm } from "react-hook-form";
import { NewRenterHandbook } from "../../handbooks/renterHandbook";
import { NewUserHandbook } from "../../handbooks/userHandbook";
import { Button, Input } from "@nextui-org/react";
import { FaTimes } from "react-icons/fa";
import { api } from "@/api";
import { useAppSelector } from "@/hooks/useAppSelector";
import { toast } from "react-toastify";

interface IEditRentForm {
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
};

export function EditMeetingRoomRentModal({ rents, scheduler, callback }: { rents: MeetingRoomRent[] | null, scheduler: SchedulerHelpers, callback: () => void }) {
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleError = useErrorHandling();

  const editRentForm = useForm<IEditRentForm>();
  const { errors } = editRentForm.formState;

  const renters = useAppSelector(state => state.handbooks.renter);
  const users = useAppSelector(state => state.handbooks.user);

  const rent = useMemo(() => {
    return rents?.find(rent => rent.id == scheduler.edited?.event_id);
  }, [rents, scheduler.edited]);

  useEffect(() => {
    if (modalErrors.length) {
      modalErrors.forEach(({ field, message }: any) => {
        editRentForm.setError(field, { type: "manual", message });
      });
    } else {
      editRentForm.clearErrors();
    }
  }, [modalErrors, editRentForm.setError, editRentForm.clearErrors]);

  useEffect(() => {
    editRentForm.setValue("renter_id", rent?.renter_id || 0);
    editRentForm.setValue("user_id", rent?.user_id || 0);
    editRentForm.setValue("rent_from", rent?.rent_from || "");
    editRentForm.setValue("rent_to", rent?.rent_to || "");
    editRentForm.setValue("description", rent?.description || "");
  }, [rent]);

  const onSubmit = (data: IEditRentForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter_id));
      const user = await getIdFromUserHandbook(String(data.user_id));

      setLoading(true);
      api.meetingRooms.updateRent(rent?.id || 0, {
        meeting_room_id: rent?.meeting_room_id || 0,
        rent_from: data.rent_from,
        rent_to: data.rent_to,
        renter_id: Number(renter),
        user_id: Number(user),
        description: data.description
      })
        .then(res => {
          callback();
          const user = users.find(user => user.id == res.data.user_id);

          scheduler.onConfirm({
            event_id: rent?.id || 0,
            title: `${renters.find(renter => res.data.renter_id == renter.id)?.display_name}: ${user?.first_name} ${user?.last_name}`,
            start: new Date(res.data.rent_from),
            end: new Date(res.data.rent_to)
          }, "edit");
          scheduler.close();
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.meetingRooms.cancelRent(rent?.id || 0)
        .then(_data => {
          callback();
          scheduler.close();
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <div className="px-6 py-4 rounded-xl flex flex-col items-start">
      <div className="flex flex-row items-center gap-4">
        <span className="text-lg font-semibold">Редактирование брони переговорки</span>
        <span className="text-foreground-400 p-2 rounded-full hover:bg-foreground-200 cursor-pointer transition-all duration-100" onClick={scheduler.close}><FaTimes /></span>
      </div>
      <form className="mt-4 flex flex-col gap-2 w-full" onSubmit={editRentForm.handleSubmit(onSubmit)}>
        <Controller
          control={editRentForm.control}
          name={"renter_id"}
          render={({ field: controllerField }) => (
            <NewRenterHandbook
              isInvalid={!!errors["renter_id"]}
              errorMessage={errors["renter_id"]?.message?.toString()}
              onSelectionChange={(key: any) => controllerField.onChange(key)}
              selectedKey={String(controllerField.value)}
              children={<></>}
            />
          )}
        />
        <Controller
          control={editRentForm.control}
          name={"user_id"}
          render={({ field: controllerField }) => (
            <NewUserHandbook
              isInvalid={!!errors["user_id"]}
              errorMessage={errors["user_id"]?.message?.toString()}
              onSelectionChange={(key: any) => controllerField.onChange(key)}
              selectedKey={String(controllerField.value)}
              children={<></>}
            />
          )}
        />
        <Controller
          control={editRentForm.control}
          name={"rent_from"}
          rules={{ required: true }}
          render={({ field: controllerField }) => (
            <Input
              variant="bordered"
              placeholder={"1"}
              label={"Время начала"}
              isRequired={true}
              isInvalid={!!errors["rent_from"]}
              type={"datetime-local"}
              errorMessage={errors["rent_from"]?.message?.toString()}
              value={controllerField.value}
              onValueChange={controllerField.onChange}
            />
          )}
        />
        <Controller
          control={editRentForm.control}
          name={"rent_to"}
          rules={{ required: true }}
          render={({ field: controllerField }) => (
            <Input
              variant="bordered"
              placeholder={"1"}
              label={"Время конца"}
              isRequired={true}
              isInvalid={!!errors["rent_to"]}
              type={"datetime-local"}
              errorMessage={errors["rent_to"]?.message?.toString()}
              value={controllerField.value}
              onValueChange={controllerField.onChange}
            />
          )}
        />
        <Controller
          control={editRentForm.control}
          name={"description"}
          rules={{ required: true }}
          render={({ field: controllerField }) => (
            <Input
              variant="bordered"
              placeholder={"Введите описание к брони"}
              label={"Описание"}
              isRequired={true}
              isInvalid={!!errors["description"]}
              type={"text"}
              errorMessage={errors["description"]?.message?.toString()}
              value={controllerField.value}
              onValueChange={controllerField.onChange}
            />
          )}
        />
        <div className="flex flex-row w-full gap-2 mt-4">
          <div className="flex flex-grow flex-row gap-2">
            <Button color="default" onClick={scheduler.close} variant="flat">
              Закрыть
            </Button>
          </div>
          <Button color="danger" onClick={onDelete} variant="flat">
            Отменить бронь
          </Button>
          <Button color="success" type="submit" variant="flat" isLoading={loading}>
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  );
}