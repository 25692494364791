import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { getIdFromLocationHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";

interface CreateMeetingRoomForm {
  office: number;
  display_name: string;
  hdmi: boolean;
}

const createFields: InputField[] = [
  {
    label: "Название переговорки",
    name: "display_name",
    placeholder: "Переговорка 1",
    type: "text",
    isRequired: true
  }, {
    label: "Кабинет",
    name: "office",
    placeholder: "",
    type: "office",
    isRequired: true
  }, {
    label: "HDMI",
    name: "hdmi",
    type: "boolean",
    placeholder: "",
    isRequired: false
  }
];

export function CreateMeetingRoomModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: CreateMeetingRoomForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const office = await getIdFromLocationHandbook(String(data.office));

      const features = [];
      if (data.hdmi) features.push("HDMI");

      api.meetingRooms.create({
        display_name: data.display_name,
        office_id: Number(office),
        picture_url: "",
        features,
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание переговорки" modalErrors={modalErrors} fields={createFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}