import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { getIdFromRenterHandbook, getIdFromUserHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { MeetingRoom } from "@/api/meetingRooms";
import ym from "react-yandex-metrika";

interface CreateMeetingRoomRentForm {
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
}

const createFields: InputField[] = [
  {
    label: "Компания",
    name: "renter_id",
    placeholder: "",
    type: "renter",
    isRequired: true
  }, {
    label: "Пользователь",
    name: "user_id",
    placeholder: "",
    type: "user",
    isRequired: true
  }, {
    label: "Время начала",
    name: "rent_from",
    placeholder: "1",
    type: "datetime-local",
    isRequired: true
  }, {
    label: "Время конца",
    name: "rent_to",
    placeholder: "1",
    type: "datetime-local",
    isRequired: true
  }, {
    label: "Описание",
    name: "description",
    placeholder: "",
    type: "text",
    isRequired: false
  }
];

export function CreateMeetingRoomRentModal({ disclosure, meetingRoom }: { disclosure: ReturnType<typeof useDisclosure>, meetingRoom: MeetingRoom | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: CreateMeetingRoomRentForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter_id));
      const user = await getIdFromUserHandbook(String(data.user_id));

      api.meetingRooms.createRent({
        meeting_room_id: meetingRoom?.id || 0,
        rent_from: data.rent_from,
        rent_to: data.rent_to,
        renter_id: Number(renter),
        user_id: Number(user),
        description: data.description
      })
        .then(_data => {
          ym('reachGoal', 'meeting_room_rented');
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Бронирование переговорки" modalErrors={modalErrors} fields={createFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}